<template>
  <a-layout
    :class="{
      'fixed-header': showHeader,
    }"
  >
    <Header v-if="showHeader" />
    <a-layout>
      <a-layout-sider theme="light" :width="168" v-if="showSider">
        <Sider />
      </a-layout-sider>
      <a-layout
        :style="{
          'min-width': '1200px',
          //'max-width': showSider ? 'calc(100vw - 228px)' : '100vw',
        }"
      >
        <a-layout-content
          :class="[
            'content',
            {
              'content-noheader': !showHeader,
            },
          ]"
        >
          <a-space
            :size="16"
            direction="vertical"
            style="width: 100%; display: flex"
          >
            <Breadcrumb v-if="showBreadcrumb" />
            <router-view />
          </a-space>
        </a-layout-content>
        <a-layout-footer class="footer">
          Copyright © 2017-{{ new Date().getFullYear() }}
          杭州象征信息技术有限公司
        </a-layout-footer>
      </a-layout>
    </a-layout>
  </a-layout>
</template>
<script lang="ts">
import { computed, defineComponent } from "vue";
import { useRoute } from "vue-router";
import useMenu from "@/use/useMenu";
import Header from "./components/Header.vue";
import Sider from "./components/Sider.vue";
import Breadcrumb from "./components/Breadcrumb.vue";

export default defineComponent({
  components: { Header, Sider, Breadcrumb },
  setup() {
    const route = useRoute();
    const { sflMenus } = useMenu();

    const showBreadcrumb = computed(() => {
      return route.matched.length > 4 && route.meta?.hide === true;
    });

    const showHeader = computed(() => {
      return route.meta?.hideHeader !== true;
    });

    const showSider = computed(() => {
      return route.meta?.hideSider !== true && sflMenus.value?.length > 0;
    });

    return {
      showBreadcrumb,
      showHeader,
      showSider,
    };
  },
});
</script>
<style lang="less" scoped>
.fixed-header {
  padding-top: 48px;
}
.content {
  background: #ebedf1;
  padding: 16px;
  min-height: calc(100vh - 48px - 66px);
  &.content-noheader {
    min-height: calc(100vh - 66px);
  }
}

.footer {
  text-align: center;
  color: rgb(147, 147, 147);
  background: #ebedf1;
}
</style>
