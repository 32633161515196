<template>
  <a-breadcrumb>
    <a-breadcrumb-item
      v-for="(item, index) in breadcrumbList"
      :key="item.meta?.code"
    >
      <template v-if="index === breadcrumbList.length - 1">
        {{ item.meta?.title }}
      </template>
      <template v-else>
        <router-link :to="item.path">{{ item.meta?.title }}</router-link>
      </template>
    </a-breadcrumb-item>
  </a-breadcrumb>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import { useRoute } from "vue-router";

export default defineComponent({
  setup() {
    const route = useRoute();

    // 是否显示面包屑
    const showBreadcrumb = computed(() => {
      return route.matched.length > 4 && route.meta?.hide === true;
    });

    // 面包屑列表
    const breadcrumbList = computed(() => {
      if (!showBreadcrumb.value) return [];
      return route.matched.slice(3);
    });

    return {
      showBreadcrumb,
      breadcrumbList,
    };
  },
});
</script>
