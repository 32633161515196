
import { computed, defineComponent } from "vue";
import { useRoute } from "vue-router";
import useMenu from "@/use/useMenu";
import Header from "./components/Header.vue";
import Sider from "./components/Sider.vue";
import Breadcrumb from "./components/Breadcrumb.vue";

export default defineComponent({
  components: { Header, Sider, Breadcrumb },
  setup() {
    const route = useRoute();
    const { sflMenus } = useMenu();

    const showBreadcrumb = computed(() => {
      return route.matched.length > 4 && route.meta?.hide === true;
    });

    const showHeader = computed(() => {
      return route.meta?.hideHeader !== true;
    });

    const showSider = computed(() => {
      return route.meta?.hideSider !== true && sflMenus.value?.length > 0;
    });

    return {
      showBreadcrumb,
      showHeader,
      showSider,
    };
  },
});
