import { computed } from "vue";
import { useStore } from "@/store/index";
import { useRoute } from "vue-router";

export const useHeadMenu = () => {
  const route = useRoute();
  const store = useStore();
  // 头部导航菜单
  const headMenu = computed(() => {
    return (store.state.permission.addRouters || []).filter((route) => {
      return route.meta?.hide !== true;
    });
  });

  const activeHeadMenu = computed(() => {
    return headMenu.value.find((menu) => menu.path === route.matched[1]?.path);
  });

  return {
    headMenu,
    activeHeadMenu,
  };
};

// 菜单相关
const useMenu = () => {
  const route = useRoute();
  const store = useStore();

  // 头部导航菜单
  const { headMenu, activeHeadMenu } = useHeadMenu();

  // sider一级菜单
  const sflMenus = computed(() => {
    return (activeHeadMenu.value?.children || []).filter(
      (route) => route.meta?.hide !== true
    );
  });
  const activeSlfMenu = computed(() => {
    return sflMenus.value.find((menu) => menu.path === route.matched[2]?.path);
  });
  // sider二级菜单
  const sslMenus = computed(() => {
    return (activeSlfMenu.value?.children || []).filter(
      (route) => route.meta?.hide !== true
    );
  });
  const activeSsfMenu = computed(() => {
    return sslMenus.value.find((menu) => menu.path === route.matched[3]?.path);
  });

  return {
    headMenu,
    activeHeadMenu,
    sflMenus,
    activeSlfMenu,
    sslMenus,
    activeSsfMenu,
  };
};

export default useMenu;
