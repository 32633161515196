import { computed } from "vue";
import { useStore } from "@/store/index";

const useUserInfo = () => {
  const store = useStore();
  // 用户信息
  const userInfo = computed(() => {
    return store.state.user.userInfo;
  });
  const username = computed(() => {
    return userInfo.value?.username;
  });
  const mobileNo = computed(() => {
    return userInfo.value?.mobileNo;
  });
  return {
    userInfo,
    username,
    mobileNo,
  };
};

// const useSystemInfo = () => {
//   const store = useStore();
//   const systemInfo = computed(() => {
//     return store.state.user.systemInfo;
//   });
//   const sysName = computed(() => {
//     return systemInfo.value?.sysName;
//   });
//   return {
//     systemInfo,
//     sysName,
//   };
// };

// const useStruInfo = () => {
//   const store = useStore();
//   const struInfo = computed(() => {
//     return store.state.user.struInfo;
//   });
//   // 组织名称
//   const struName = computed(() => {
//     return struInfo.value?.struName;
//   });
//   // 角色列表
//   const roleList = computed(() => {
//     return struInfo.value?.roleList || [];
//   });
//   return {
//     struInfo,
//     struName,
//     roleList,
//   };
// };

const usePermission = () => {
  const store = useStore();
  const permission = computed(() => {
    return store.state.user.permission;
  });
  return {
    permission,
  };
};

const useTeamInfo = () => {
  const store = useStore();
  const teamInfo = computed(() => {
    return store.state.user.teamInfo;
  });
  return {
    teamInfo,
  };
};

const useApplyInfo = () => {
  const store = useStore();
  const applyInfo = computed(() => {
    return store.state.user.applyInfo;
  });
  return {
    applyInfo,
  };
};

// 用户相关的hooks
const useUser = () => {
  return {
    ...useUserInfo(),
    // ...useSystemInfo(),
    // ...useStruInfo(),
    ...usePermission(),
    ...useTeamInfo(),
    ...useApplyInfo(),
  };
};

export default useUser;
